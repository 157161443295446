/* scroll bar */
/* @import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css'; */
@import 'simplebar-react/dist/simplebar.min.css';

/*apex-chart*/
@import '../../public/assets/third-party/apex-chart.css';
@import '../../public/assets/third-party/react-table.css';

.css-13ddxbb > * + * {
  margin: 0 !important;
}

.MuiSnackbar-root {
  z-index: 999999;
}

body {
  overflow-y: scroll !important;
  padding: 0 !important;
}

body.drawer-open {
  overflow-y: hidden !important;
}

.MuiAppBar-root {
  padding: 0 !important;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

.fitx-date-picker {
  position: absolute !important;
  transform: translateY(45px);
}

.Mui-disabled {
  cursor: not-allowed;
}

.react-tabs__tab-list{
  border-bottom: 1px solid rgb(230 235 241) !important;
  position: relative;
  z-index: 9;
}

.react-tabs__tab--selected{
  border: 1px solid rgb(230 235 241) !important;
  border-bottom: none !important;
}

@media only screen and (max-width: 600px) {
  .MuiBox-root .react-tabs__tab-panel ul {
    margin-top: 30px;
  }
}

.sigCanvas{
 border: 2px dashed #6591fa;
}

.swal2-container {
  z-index: 1400;
}

.swal2-html-container {
  color: #262626 !important;
}
